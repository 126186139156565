.form_radio {
    margin-bottom: 25px;
    padding-bottom: 25px;
    border-bottom: 1px solid #dbdbdb;
}
.form_radio input[type=radio] {
    display: none;
}
.form_radio label {
    display: inline-block;
    cursor: pointer;
    font-size: 17px;
    position: relative;
    padding-left: 25px;
    margin-right: 0;
    line-height: 18px;
    user-select: none;
}
.form_radio label:before {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    position: absolute;
    left: 0;
    bottom: 1px;
    background: url('/public/img/radio-1.png') 0 0 no-repeat;
}

/* Checked */
.form_radio input[type=radio]:checked + label:before {
    background: url('/public/img/radio-2.png') 0 0 no-repeat;
}

/* Hover */
.form_radio label:hover:before {
    filter: brightness(120%);
}

.question_box{
    -webkit-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
    background: #ffffff;
    padding: 26px;
    width: 600px;
}

.question_box p{
    font-weight: 600;
    color: #3e3e3e;
    margin: 43px 0;
}