.fit{
    width: fit-content
}
.table td, .table th{

    line-height: 1.5;
    padding: 20px;

    font-size: 14px;
}
.table td{
    color: #646464;
    text-align: center;
    vertical-align: middle;
}
.table th{
    color: #000000;
    position: relative;
    vertical-align: top;
    word-wrap: break-word;
}
.table a{
   /* color: #000000; */
    text-decoration: none;
}
.sea{
    color: #17A2B8;
}
.table th span{
    margin-left: 10px;
}
.table div[title^='Toggle SortBy']:before{
    content: '';
    border: 4px solid transparent;
    border-bottom-color: #ced4da;
    position: absolute;
    z-index: 10;
    top: 18px;
    right: 8px;

}
.table div.desc:before{
    border-bottom-color: #17A2B8 !important;
}
.table div.asc:after{
    border-top-color: #17A2B8 !important;
}


.table div[title^='Toggle SortBy']:after{
    content: '';
    border: 4px solid transparent;
    border-top-color: #ced4da;
    position: absolute;
    z-index: 10;
    top: 30px;
    right: 8px;
}
.paging_simple_numbers{
    float: right;
    text-align: right;
    padding-top: 0.25em;
}
.paging_simple_numbers button{
    cursor: default;
    color: #333333;
    border: 1px solid transparent;
    background-color: #e9ecef;
    box-shadow: none;
    box-sizing: border-box;
    display: inline-block;
    min-width: 1.5em;
    padding: 0.5em 1em;
    margin-left: 2px;
    text-align: center;
    border-radius: 2px;
}

.currentPage{
    background-color: #17A2B8 !important;
    background-image: none;
    border-color: transparent;
    color: #fff !important;
}

.paging_simple_numbers button:hover{
    cursor: pointer;
}
.paginate_button:disabled{
    background-color: #e9ecef;
    color: #adb5bd !important;
}
.paginate_button:disabled:hover{
    cursor: unset !important;
}

.filterInput input, .filterInput select{
    width: 103%;
    border: 1px solid #dee2e6;
    padding: 5px;
    border-radius: 3px;
    box-shadow: inset 0 0 4px #dee2e6;
    background: #ffffff;
}
.filterInput input:focus, .filterInput select:focus{
    outline: none;
}

.true, .false{
    width: 20px;
    height: 20px;

    border-radius: 50%;
}
.true{
    background: green;
}
.false{
    background: red;
}
.tableAvatar{
    width: 75px;
    box-shadow: 0 1px 4px 0px rgb(0 0 0 / 20%);
}
.countryTable{
    display: flex;
    align-items: center;
    justify-items: center;
}

.countryTable img{
    width: 20px;
    height: 13px;
    margin-right: 5px;
}
.resetButton{

    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    background-color: #e2e6ea;
    border: 1px solid #dae0e5;
    padding: 0.375rem 0.75rem;
    font-size: 12px;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.responsibleTable img{
    width: 25px;
    margin-right: 5px;
}

.deleteIcon{
    cursor: pointer;
    color: rgb(229, 29, 48);
}