.timer{
    text-align: right;
}

.question_place{
    display: flex;
    justify-content: center;
    align-items: center;
}

.question_buttons_start, .question_buttons_main{
    display: flex;

}
.question_buttons_end button{
    float: right;
}
.question_buttons_main >div{
    width: 50%;
}

.question_buttons_start button{
    margin-right: 15px;
}