.my_results_line{
    display: flex;
    justify-content: space-between;
}
.my_results_line > div{
    width: 47%;
}

.my_results_line_list{
    padding-top: 32px;
}

.my_results_detail{
    border: 1px solid #cbcbcb;
    padding: 15px;
    border-radius: 3px;
    margin-top: 45px;
}

.my_results_detail h2{
    font-size: 18px;
}
.my_results_detail h3{
    font-size: 16px;
}

.fake_link{
    cursor: pointer;
    color: #0c3981;
}
