*{
    padding: 0;
    margin: 0;
}
.topbar {
    width: 100%;
    height: 65px;
    background-color: white;

}
.red{
    color: red;

}
.mainColor{
    color: #2b4963;
}
.topbarWrapper {
    height: 100%;
    display: flex;

    color: #868e96;
    box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.2);
}
.topbarMain{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.menuIcon{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
}
.topbarLeft{
    width: 181px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.nameCompany{
    font-weight: bold;
    font-size: 20px;
}
.logoComment{
   font-style: italic;
    font-size: 11px;
}
.topbarUser{
    margin-right: 25px;
}
.topbarUser>div{
    cursor: pointer;
    display: flex;
    align-items: center;
}
.topbarUser>div>span{
    margin-right:5px;
}
.topbarUser img{
    opacity: .85;
    transition: all 0.2s ease-in-out;
    width: 32px;
}
.rounded-circle{
    border-radius: 50%;
}
.topbarLeft img{
    width: 100px;
}


.topbarBlock{
    border-right: 1px solid rgba(0, 0, 0, 0.15);
    height: 65px;
}

.languageUserBlock{
    display: flex;
}
.languageUserBlock div{
   margin-left: 10px;
}

.profileDropDown{
    position: absolute !important;
    right: 0;
    top: 65px;
    padding: 0 10px 10px;
    width: 200px;
    background: #ffffff;
    box-shadow: 0 1px 4px 0px rgb(0 0 0 / 16%);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.profileDropDown::before {
    content: '';
    position: absolute;
    top: -4px;
    left: -2px;
    right: 0;
    height: 4px;
    background-color: #fff;
    z-index: 1040;
}
.profileDropDown span, .dropdown li a{
    color:  #868e96;
}
.profileDropDown li a:hover, .dropdown li span:hover{
    background-color: #dee2e6;
    color:  #000000;
}
.profileDropDown li a, .dropdown span{
    font-size: 13px;
    text-decoration: none;
    display: block;
    padding: 7px 10px;
    border-radius: 2px;
    transition: all 0.2s ease-in-out;
}
.profileDropDown ul{
    list-style-type: none;
    padding-left: 0;
}