body{
    font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
}

.dashboard{
    display: flex;
    min-height: 100vh;
  }

.loginModal input{
    margin-bottom: 10px;
    padding: 5px;
    background-color: rgb(232, 240, 254) !important;
    border: 1px solid rgb(232, 240, 254);

}
.errorLogin {
    color: darkred;
}
.loginModal button{
    border: 1px solid transparent;
}

.topbarMain:hover{
    cursor: pointer;
}
.userMenuIcon{
    width: 20px;
    line-height: 0;
    text-align: center;
    font-size: 13px;
    position: relative;
    color: #17a2b8;
    top: 0;
    margin-right: 5px;
}
.topbarUser img{
    margin-left: 5px;
}

.calendar-timeline{
    display: flex;
}

.calendar-entity{
    width: 40px;
    height: 30px;
    border: 1px solid #e5e5e5;
}
.active-entity:hover{
    cursor:pointer;
}
.calendar-timeline-day-date{
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 10px;
}

.courses{
    display: flex;
}

.course-unit{
    margin-right: 33px;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);

    width: 211px;
    height: 134px;
}

.course-unit:hover{
    -webkit-box-shadow: 0px 0px 8px 7px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 0px 8px 7px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 0px 8px 7px rgba(34, 60, 80, 0.2);
}

.course-unit-body{
    padding: 10px;
    height: 100px;

    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: end;

}

.course-unit-bottom-buttons{
    display: flex;
    justify-content: space-between;

}

.course-unit-bottom-buttons > div{
 width: 44%;
    font-size: 14px;
    padding: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    text-align: center;

}
.course-unit-bottom-buttons > div:hover{
    cursor: pointer;
}

.course-unit-bottom-buttons > div:first-child{
    background: linear-gradient(184deg, rgba(254,254,254,1) 0%, rgba(224,224,224,1) 100%);

}

.course-unit-bottom-buttons > div:last-child{
    background: linear-gradient(184deg, rgba(240,171,76,1) 0%, rgba(237,148,22,1) 100%);
    color: #f7f7f7;
}

.course-unit-title{
    background: #fff;
    padding: 4px;
    font-size: 12px;

    text-align: center;

    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.course-unit-cost{
    background: rgba(255,255,255, 0.7);
    padding: 3px;
    font-size: 15px;
    border-radius: 3px;
    font-weight: 700;
}

.pay{
    color: #a11313;
}
.free{
    color: #d45f47;
}

.activity-type-list{
    display:flex;
    align-items: center;
    margin-bottom: 10px;


}
.dragActivity{
    width: 300px;
    background: #f5f4f4;
    border-top: 2px solid transparent;
}

.dragActivity.dragstart{
opacity: 0.5;
}

.dragActivity.dragover{
    border-top-color: green;
}

.color__block{
    width: 40px;
    height: 40px;
    border: 1px solid #e5e5e5;

}

.activityMenu, .minutesInput{
    display: block;
    position: absolute;
    background: rgba(0,0,0, 0.8);

    color: #fff;
    font-size: 10px;
}
.activityMenu{
    padding: 5px;
}

.minutesInput{
    padding: 1px;
}

.minutesInput input{
    width: 50px;
    background: rgba(0,0,0, -0.2);
    color: #ffffff;
}

.activityMenu:hover{
    cursor: pointer;
}

.activityMenu ul{
    list-style: none;
    margin: 0;
    padding: 0;
}

.activityMenu ul li{
    padding: 4px 0;

}

.month-block{
    margin-bottom: 20px;
    width: fit-content;
}
.month-block >div:first-child{
    margin-bottom: 10px;
}

.activity__types__near{
    display: flex;
    flex-direction: column;
    margin-right: 15px;
}
.activity__types__near > div{
    height: 32px;
    display: flex;
    align-items: center;
}

.main__month__block{
    display: flex;
}

.short__types__color{
    width: 15px;
    height: 21px;
    margin-right: 5px;
}

.under__month__information{
    display: flex;
    justify-content: space-between;
}

.month__settings{
    color: #adb5bd !important;
}

.month__settings:hover{
    cursor: pointer;
}

.background__picker{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
}

.my__picker{
    position: absolute;
}
.add__new__activity__type{
    display:flex;
    align-items: center;
}

.add__new__activity__type div, .add__new__activity__type input, .add__new__activity__type button{
    margin-right: 4px;
}

.new__input{
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    height: 40px;
    padding: 0 5px;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.new__input:focus{
    outline: none;
    border:1px solid #0a58ca;
}

.select__day{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 300px;
}
.select__day div{
    width: 60px;
    height: 30px;
    border: 1px solid #e5e5e5;
    display: flex;
    justify-content: center;
    align-items: center;
}

.select__day div:hover{
    cursor: pointer;
}

.iconItem{
    color: #8b8b8b;
    width: 16px !important;
    height: 16px !important;
}

.iconDrag{
    color: #8b8b8b;
}

.iconItem:hover{
    cursor: pointer;
}

.empty__type__list{
    height: 18px;
}

.child__modal{
    margin-bottom: 15px;
}

.tracker__list__dashboard{
    display: flex;
    padding: 10px 0 20px 0;
    border-bottom: 1px solid #000000;
}

.tracker__list__dashboard > div:hover{
    cursor: pointer;
}

.tracker__list__dashboard > div{
    margin-right: 10px;
    padding: 10px;

}

.active__tracker{
    color: #ffffff;
    background: #1D2939;
    border-radius: 6px;
}

.tracker__initialization{
    padding: 15px 0;
    display: flex;
    justify-content: center;
}