.main{
    width:100%;
    background-color: #E9ECEF;

}

.pagebody, .underPageBody{
    padding: 0 30px;
    margin-top: 30px;
}
.section-wrapper{
    padding: 60px;
    border-radius: 3px;
    background-color: #f5f4f4;
    box-shadow: 0px 1px 3px 0px rgb(0 0 0 / 21%);
}

.underPageBody h1{
    color: #343a40;
    font-size: 25px;
}
.tableButtons{
    padding: 0 0 10px;
}
.tableButtons button{
    margin-right:10px;
}
.error{
    margin-top: 20px;
    color: red;
    text-align: center;
}

.overflowX{
    overflow-x: scroll;
}
