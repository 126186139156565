

.result_answer_answer, .result_answer_correct{
    margin: 0 !important;
}

.result_answer_description{
    margin: 41px 0 0 0 !important;
}

.result_answer_answer{
    color: red !important;
    text-decoration: line-through;
}
.result_answer_correct{
    color: green  !important;
}