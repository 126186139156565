.test{
    padding: 17px;
    background:  #e7e7e7;
    width: 300px;
    height: 600px;
    border-radius: 15px;
}

.test__name{
    font-weight: 700;
    font-size: 20px;
}

.test__description{
    margin: 40px 0;
    font-weight: 400;
    font-size: 15px;

}

.test__detail > div{
    display: flex;
    justify-content: space-between;
    padding-bottom: 13px;
}
.test__detail > div:last-child{
    font-size: 20px;
}
.test__image img{
    width: 100%;
}

.test__select__plan{
    display: flex;
    align-items: center;
}

.test__select__plan span{
    padding: 5px 10px;
    border-radius: 15px;
    font-size: 11px;
    border: 1px solid #c0c0c0;
    color: #c0c0c0;
    margin-left: 7px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.active__plan{
    border: 1px solid #222222 !important;
    color: black  !important;
}

.test__select__plan span:hover{
 cursor: pointer;
}
