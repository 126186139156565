.button__inside__flex{
    display: flex;
    align-items: center;
}

.button__inside__flex button{
    margin-top: 29px;
}
.avatar{
    width: 200px;
    height: 200px;
}
