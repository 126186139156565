.input, .select, .file-select{
    width:100%;
    flex: 1 1 auto;
    margin: 0 5px;
}
.textarea{
    width:100%;
    margin: 0 5px;
}

.form-control-label{
    margin-bottom: 0.5rem;
    font-size: 0.875rem;
    font-weight: normal;
    line-height: 1.5;
    color: #868ba1;
}
.input input, .select select, .textarea textarea {
    font-family: inherit;
    display: block;
    width: 100%;
    padding: 0.65rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.25;
    color: #495057;
    background-color: #fff;
    background-image: none;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.input input:focus, .select select:focus, .textarea textarea:focus, .inputTag input:focus, .emptyInput:focus {
    border-color: #55a6f8;
    outline: none;
}

.input-line{
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
}


.createdTime {
    display: flex;
    font-size: 12px;
}
.createdTime span {
    color: #5e5e5e;
}
.createdTime div{
    margin: 0 5px;
}

.editIndicate{
    width: 15px !important;
    margin-left: 3px;
}
.doneIndicate{
    color: rgb(0, 136, 0);
}
.waitIndicate{
    color: rgb(255, 183, 58);
}
.errorIndicate{
    color: rgb(229, 29, 48);
}
.custom-file {
    position: relative;
    display: inline-block;
    max-width: 100%;
    height: 2.5rem;
    margin-bottom: 0;
}
.custom-file-input{
    min-width: 14rem;
    max-width: 100%;
    height: 2.5rem;
    margin: 0;
    opacity: 0;

}
.custom-file-control span {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 5;
    height: 2.5rem;
    padding: 0.5rem 1rem;
    line-height: 1.5;
    color: #495057;
    pointer-events: none;
    user-select: none;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 3px;
}
.custom-file-control span::before {
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    z-index: 6;
    display: block;
    height: 2.5rem;
    padding: 0.5rem  1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #e9ecef;
    border: 1px  solid rgba(0, 0, 0, 0.15);
    border-radius: 0 3px 3px   0;
}
.empty::after {
    content: "Пусто...";

}
.full::after {
    content: "Ok";
    color: rgb(0, 136, 0);
    font-weight: bold;
}
.custom-file-control span::before {
    content: "Выбрать";
}
.pictureSelected{
    width: 65px;
    margin-right: 10px;
}
.fixed-bottom-right{
    bottom: 10px;
    right: 10px;
    position: fixed;
    z-index: 999;
}
.disconnect{

    color: rgb(255, 183, 58) !important;
}
.remove{

    color: rgb(229, 29, 48) !important;
}

.remove svg, .disconnect svg{
    cursor: pointer;
}
.table-celebrities td, .table-celebrities th{
    padding: 10px;
    border-bottom: 1px solid rgba(0,0,0,.125);
}

.card-footer{
    display: flex;
}
.map{
    width: 100%;

}

.topGallery{
    width: 100%;
}
.myGallery {
display: flex;
    align-content: space-between;
    flex-wrap: wrap;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    padding: 10px;
}

.myGallery div > img{
    width: 200px;

}

.around-photo{
    margin-right: 15px;
    margin-bottom: 15px;
   padding: 10px;
    -webkit-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
    box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
}
.around-photo:hover{
    -webkit-box-shadow: 4px 4px 8px 2px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 4px 4px 8px 2px rgba(34, 60, 80, 0.2);
    box-shadow: 4px 4px 8px 2px rgba(34, 60, 80, 0.2);
}

.around-photo div{
    text-align: right;
}
.around-photo div svg{
    color: rgb(229, 29, 48);
    cursor: pointer;
}
.addPhoto{
    display: flex;
    justify-content: center;
    align-items: center;

}
.addPhoto svg{
    font-size: 50px;
    color: rgb(0, 136, 0);
}
.addPhoto svg:hover{
    cursor:pointer;
}
.addPhoto>input {
    display: none;
}
.inputTag{
    margin-left: 7px;
}

.inputTag input {
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    padding: 0.65rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.25;
    color: #495057;
}
.emptyInput{
   border: 1px solid #ffffff;
    padding: 2px;
    border-radius: 3px;
    background: #ffffff;
}
.myEmptyTable{
    display: flex;
    flex-direction: column;
}
.w25{
    width: 25px;
}

