.information__div__main{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.information__div{
    -webkit-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
    width: 500px;
padding:20px;
    background: #fff;
}