.loginModal{
    background: rgba(255, 255, 255, 0.4);
    padding:30px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
}
.loginPage{
    background: rgb(214,145,136);
    background: linear-gradient(152deg, rgba(214,145,136,1) 0%, rgba(43,73,99,1) 71%, rgba(36,83,144,1) 100%);
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
}
.wrongPassword{
    color: red;

    background: rgba(255,255,255,0.7);
    padding: 5px;
}

.select__reg_log{
    cursor: pointer;
}

.red__border{
    border: 1px solid red;
}

.auth__menu{
    color: #002066;
    margin-top: 24px;
}

.flex-auth-menu{
    display: flex;
}
.flex-auth-menu div{
    margin-right: 10px;
}

.languageLogin{
    background: #fff;
    margin-bottom: 15px;
}
