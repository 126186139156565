.sidebar{
    width:179px;
    background-color: #1D2939;
   /* height: 100vh; */
    padding: 0 10px 0 10px;
    color: #adb5bd;
}
.sidebar hr{
    border-top: 1px solid rgba(173,181,189, 0.5);
    border-bottom: 0;
}
.headline{
    color: #17a2b8 !important;
    font-weight: bold;
    margin-bottom: 10px;
}
.sidebar-label {
    display: block;
    text-transform: uppercase;
    font-size: 10px;
    font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
    color: #fff;
    letter-spacing: 1px;
    opacity: .7;
    white-space: nowrap;
    margin-top: 20px;
    padding-left: 15px;
    padding-right: 15px;
}

.leftMenu{
    margin-top: 20px;

}
