.menuItem{
    display:flex;
    align-items: center;
    height: 40px;
    cursor:pointer;
    padding: 0 10px;
    border-radius: 2px;
    color: #adb5bd !important;
    text-decoration: none;
}
.menuItem:hover{
    background-color: #0f171d;
}
.menuItemIcon svg{
    color: #adb5bd !important;
    margin-right: 6px;
    /* height: 22px !important;
     width: 22px !important;*/
    font-size: 21px;
}
.menuDown svg{
    font-size: 19px;
    margin-top: 2px;
    margin-left: 5px;
}
summary.menuItem{
    justify-content: space-between;
}
.menuItemInside{
    display:flex;
    align-items: center;
}
.menuItemTitle{
    font-size: 12px;
    letter-spacing: 1px;

}

.menuSubmenu{
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 27px;
    background-color: #1a2432;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    list-style: none;
}
.menuSubmenu li{
    font-size: 13px;

    padding: 0.5rem 1rem;
    white-space: nowrap;
    position: relative;
    transition: all 0.2s ease-in-out;

}
.menuSubmenu li a{
    color: #adb5bd;
    text-decoration: none;
}
.menuSubmenu span::before {
    content: ' ';
    position: absolute;
    top: 50%;
    left: -5px;
    margin-top: -.5px;
    width: 5px;
    height: 1px;
    background-color: #fff;
    opacity: .5;
    transition: all 0.2s ease-in-out;
}
.menuSubmenu span:hover{
    color: #17a2b8;
    cursor: pointer;
}
.menuSubmenu span:hover:before{
    background-color: #17a2b8;
    width: 15px;
}